import routes from "common/routes/routes";
import { css } from "common/utils/stitches.config";
import Button from "components/elements/button";
import NotFoundComponent from "components/elements/not-found";
import { useRouter } from "next/router";
import { useTranslation } from "react-i18next";

export default function NotFoundPage() {
  const router = useRouter();
  const { t } = useTranslation();
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div
        style={{
          flexDirection: "column",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "10vh",
        }}
      >
        <p className={styles.title()}>{t("common.label_not_found")}</p>
        <NotFoundComponent />
        <p className={styles.description()}>
          {t("common.description_not_found")}
        </p>
        <Button
          type="confirmation"
          className={styles.buttonStyle()}
          label={t("common.button_not_found")}
          onClick={() => router.push(routes.asset)}
        />
      </div>
    </div>
  );
}

const styles = {
  title: css({
    fontSize: 30,
  }),
  description: css({
    marginBottom: 40,
  }),
  buttonStyle: css({
    width: "20%",
  }),
};
